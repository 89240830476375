#snackbar-content-wrapper {
    display: flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 72px;
    background: #141a20;
    box-shadow: 6px 12px 32px 0px rgba(149, 149, 149, 0.1);
}

.snackbar-text {
    color: #8edad4;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
}
