#waitlist-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#waitlist-lottie {
    width: 20vh;
    height: 20vh;
    align-content: center;
}

#confetti-lottie {
    position: absolute;
    display: none;
    top: 40vh;
    left: calc(50% - 30vw);
    width: 60vw;
    height: 30vw;
}

#waitlist-content-wrapper {
    width: 60%;
    margin: 56px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.waitlist-logo-img {
    width: 106px;
    height: 48px;
}

#waitlist-footer {
    padding: 2rem 70px;
    width: 100%;
}

#waitlist-text {
    color: #020024;
    text-align: center;

    /* material-theme/headline/small */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
}

#waitlist-title {
    color: #020024;

    /* material-theme/display/medium */
    font-family: "Arima";
    font-size: 57px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px; /* 112.281% */
    letter-spacing: -0.25px;
}

.fade-in {
    opacity: 0;
    animation: fadeInBottom 1s ease-in-out 0.5s forwards;
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #waitlist-content-wrapper {
        width: 90%;
        margin: 56px 0;
    }

    #waitlist-footer {
        padding: 2rem 20px;
        width: 100%;
    }

    #waitlist-text {
        font-size: 18px;
        line-height: 24px; /* 133.333% */
    }

    #waitlist-title {
        font-size: 32px;
        line-height: 120%;
    }
    .waitlist-logo-img {
        width: 80px;
        height: 36px;
    }

    #confetti-lottie {
        position: absolute;
        top: 50vh;
        left: calc(50%);
        transform: translateX(-50%);
        width: 100vw;
        height: 60vw;
    }
}
