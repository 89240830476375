.card {
    background-color: transparent;
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.6;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    position: relative;
    flex-direction: row;
    width: 90%;
}

.card.expanded {
    opacity: 1;
    background-color: rgba(175, 229, 225, 0.1);
}
.filling-bar {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    width: 5px;
    transition: 0.1s linear;
    border-top-left-radius: 5px;
    /* border-bottom-left-radius: 5px; */
}

.feature-col-1 {
    display: flex;
    flex-direction: column;
    width: 80px;
    justify-content: left;
    align-items: start;
}

.feature-item-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: left;
    align-items: start;
}

.icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.feature-item-title {
    color: var(--Foundation-White-W50, #fefefe);
    text-align: center;

    /* material-theme/title/large */
    font-family: "Arima";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    text-transform: uppercase;
}
.feature-item-subtitle {
    color: var(--Foundation-White-W50, #fefefe);

    /* material-theme/title/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.feature-item-description {
    color: var(--Foundation-Blue-B50, #ecf9f8);

    /* material-theme/body/medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.card.expanded .feature-item-description {
    opacity: 1;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .card {
        width: 100%;
    }
}
