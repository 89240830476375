.join-btn {
    display: inline-flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 2px solid var(--Foundation-Blue-B300, #3cbfb5);
    background: var(--Foundation-Blue-B300, #3cbfb5);
    width: fit-content;
}

.join-btn-text {
    color: var(--Foundation-White-W50, #fefefe);
    text-align: center;

    /* material-theme/label/large */
    font-family: "Arima";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: 0.1px;
}
