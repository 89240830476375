#impact-header {
    position: absolute;
    left: 0;
    top: -10vh;
    width: 60%;
    text-align: left;
}

#impact-title {
    align-self: stretch;
    color: var(--Foundation-Dark-B500, #141a20);

    /* material-theme/display/medium */
    font-family: "Arima";
    font-size: 57px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 112.281% */
    letter-spacing: -0.25px;
}

#impact-para {
    color: var(--Foundation-Dark-B300, #202a34);

    /* material-theme/headline/small */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #impact-header {
        width: 100%;
        padding: 0 20px;
        text-align: center;
        position: static;
    }

    #impact-title {
        font-size: 32px;
        line-height: 120%;
    }

    #impact-para {
        font-size: 18px;
    }
}
