#footer-section {
    margin: 15vh 0 5vh 0;
}

#footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
}

#policy-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

#policy-wrapper {
    display: flex;
    flex-direction: row;
}

.footer-text {
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px dotted;
}

#copyright-text {
    color: #959595;

    /* material-theme/label/medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.5px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #policy-row {
        flex-direction: column-reverse;
        height: 8vh;
        justify-content: space-between;
        align-items: center;
    }
}
