#feel-secure-section {
    display: flex;
    justify-content: center;
    padding: 5rem 0;
}

#feel-secure-title {
    align-self: stretch;
    color: var(--Foundation-Dark-B500, #141a20);
    text-align: center;

    /* material-theme/display/medium */
    font-family: "Arima";
    font-size: 57px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px; /* 112.281% */
    letter-spacing: -0.25px;
}
#feel-secure-content-wrapper {
    width: 80vw;
}

#feel-secure-para {
    color: var(--Foundation-Dark-B300, #202a34);
    text-align: center;

    /* material-theme/headline/small */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
}

#feel-secure-highlight {
    color: var(--Foundation-Dark-B300, #202a34);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.feature-cards-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #feel-secure-title {
        font-size: 32px;
        line-height: 120%;
    }
    #feel-secure-content-wrapper {
        width: 85%;
    }

    #feel-secure-para {
        font-size: 18px;
        line-height: 32px; /* 133.333% */
    }

    #feel-secure-highlight {
        font-size: 18px;
        line-height: 32px;
    }

    .feature-cards-row {
        flex-direction: column;
        justify-content: space-between;
    }
}
