@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arima:wght@100..700&display=swap");

:root {
    /* screen size width definition */
    --mobile-width: 600px;
    --tablet-width: 1024px;
    --desktop-width: 1300px;

    /* global padding */
    --section-horizontal: 6rem;

    /* font size definition */

    /* for desktop */
    --desktop-display-large-text: 57px;
    --desktop-display-large-text-height: 64px;
    --desktop-display-med-text: 45px;
    --desktop-display-med-text-height: 52px;
    --desktop-heading-large-text: 32px;
    --desktop-heading-large-text-height: 40px;
    --desktop-highlight-text: 24px;
    --desktop-highlight-text-height: 32px;
    --desktop-sub-heading-text: 24px;
    --desktop-sub-heading-text-height: 32px;
    --desktop-body-large-text: 16px;
    --desktop-body-large-text-height: 24px;
    --desktop-body-med-text: 14px;
    --desktop-body-med-text-height: 20px;
    --desktop-body-small-text: 12px;
    --desktop-body-small-text-height: 16px;
    --desktop-cta-label-large-text: 16px;
    --desktop-cta-label-large-text-height: 24px;
    --desktop-label-med-text: 12px;
    --desktop-label-med-text-height: 16px;
    --desktop-label-small-text: 10px;
    --desktop-label-small-text-height: 16px;
    --desktop-title-large-text: 20px;
    --desktop-title-large-text-height: 28px;
    --desktop-title-med-text: 16px;
    --desktop-title-med-text-height: 24px;
    --desktop-title-small-text: 14px;
    --desktop-title-small-text-height: 20px;

    /* for tablet */

    /* for mobiles */

    /* for small-mobiles */

    /* colors definition */
    --bg-color: #fbfbfb; /* bg color */
    --foundation-dark: #202a34;

    /* font definition */
    --inter-ff: "Inter";
    --arima-ff: "Arima";
}

* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: var(--inter-ff);
    color: var(--foundation-dark);
}

body {
    padding: 0 0;
    overflow-x: hidden;
    background-color: var(--bg-color);
}

.scroll-lock {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.size {
    @apply w-[95%] md:w-[90%] mx-auto;
}

.shadows {
    box-shadow: 0px 0px 3px 0.3px rgb(182, 182, 182);
}

.btn {
    @apply px-3 p-2 text-sm font-medium;
}

.center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.write .ql-editor {
    font-size: 1.2rem !important;
    padding: 0 !important;
}

/* Typography */
.disp-l-arima-text {
    font-family: var(--arima-ff);
    font-size: var(--desktop-display-large-text);
    line-height: var(--desktop-display-large-text-height);
}
