.signup-btn {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--Foundation-Dark-B300, #202a34);
    outline: none;
}

.signup-btn-text {
    color: var(--Foundation-Dark-B50, #e9eaeb);
    font-family: "Arima";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
