.feature-card-wrapper {
    width: 335px;
    height: 349px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 14px 92px 0px rgba(32, 42, 52, 0.05);
    padding: 1rem 1.5rem;
}

/* .feature-card-wrapper:hover {
    scale: 1;
    transition: 200ms;
} */

.feature-card-title {
    color: var(--Foundation-Dark-B300, #202a34);
    text-align: center;

    /* material-theme/title/Title Large 2 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
}

.feature-card-content {
    color: var(--Foundation-Dark-B100, #7e8389);
    text-align: center;

    /* material-theme/body/large */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .feature-card-wrapper {
        width: 100%;
        height: 349px;
    }
    .feature-card-title {
        font-size: 16px;
        line-height: 24px;
    }
    .feature-card-content {
        font-size: 14px;
        line-height: 20px;
    }
}
