.copy-link-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    padding: 8px 8px 8px 24px;
    gap: 48px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 6px 12px 32px 0px rgba(149, 149, 149, 0.1);
    z-index: 1;
}

.copy-link-wrapper:hover {
    cursor: pointer;
}

#copy-btn {
    display: inline-flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 2px solid #3cbfb5;
    background: #3cbfb5;
}

.copy-text {
    color: #a4a8ac;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .copy-link-wrapper {
        width: 80%;
    }
    #copy-btn {
        padding: 4px;
    }
}
