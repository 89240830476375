#hero-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.hero-section-wrapper {
    /* width: 65%; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5rem 0 10rem 0;
}
.hero-section-content-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 55%;
}

.hero-text {
    color: var(--Foundation-Dark-B500, #141a20);
    text-align: center;

    /* material-theme/display/large */
    font-family: "Arima";
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 115.2px */
    letter-spacing: -0.25px;
}

.hero-para-text {
    color: var(--Foundation-Dark-B300, #202a34);
    text-align: center;

    /* material-theme/headline/small */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
}

.hero-para-highlight {
    color: var(--Foundation-Dark-B500, #141a20);

    /* material-theme/headline/medium */
    font-family: "Arima";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}
.coming-soon-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.coming-soon-text {
    color: #464e57;
    text-align: center;

    /* material-theme/body/large */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}
.coming-soon-logo-text {
    color: #a4a8ac;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .hero-section-wrapper {
        padding: 3rem 0 0 0;
    }
    .hero-section-content-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 90%;
    }

    .hero-text {
        font-size: 42px;
    }

    .hero-para-text {
        font-size: 18px;
    }

    .hero-para-highlight {
        font-size: 18px;
        line-height: 120%;
    }
    .coming-soon-logo-text {
        font-size: 16px;
        line-height: 24px;
    }
}
