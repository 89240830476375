.section-wrapper {
    padding: 10vh 5vw;
    font-size: 16px;
    word-spacing: 5px;
    line-height: 24px;
}

.heading-1 {
    font-size: 36px;
    font-weight: 400;
    margin: 2rem 0;
    line-height: normal;
}

.heading-2 {
    font-size: 24px;
    font-weight: 400;
}

.para {
    font-size: 16px;
    word-spacing: 5px;
    margin: 1rem 0;
}

li {
    margin: 1rem 0;
}

ul {
    padding-left: 14px;
    list-style: disc;
}

ol {
    padding-left: 14px;
}

a {
    color: blue;
    font-weight: 500;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .heading-1 {
        font-size: 28px;
    }

    .heading-2 {
        font-size: 20px;
    }

    .para {
        font-size: 14px;
        word-spacing: 4px;
    }

    ul {
        padding-left: 12px;
    }

    ol {
        padding-left: 12px;
    }
}
