.subscribe-form-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;

    justify-content: left;
    align-items: center;
    width: 100%;
    height: 72px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
    box-shadow: 6px 12px 32px 0px rgba(149, 149, 149, 0.1);
}

#subscribe-form-email-input {
    margin-left: 24px;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */

    width: 400px;
    outline: none;
    width: 38%;
}

#subscribe-form-name-input {
    margin-left: 24px;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    width: 400px;
    outline: none;
    width: 25%;
}

#subscribe-form-btn {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    position: absolute;
    right: 13px;
    top: 8px;

    border-radius: 8px;
    border: 2px solid var(--Foundation-Blue-B300, #3cbfb5);
    background: var(--Foundation-Blue-B300, #3cbfb5);
}

#subscribe-form-btn-text {
    color: var(--Foundation-White-W50, #fefefe);
    text-align: center;

    /* material-theme/label/large */
    font-family: "Arima";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    letter-spacing: 0.1px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .subscribe-form-wrapper {
        flex-direction: column;

        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 25vh;
        padding: 12px;
    }

    #subscribe-form-email-input {
        width: 100%;
        height: 50px;
        margin: 0;
    }

    #subscribe-form-name-input {
        width: 100%;
        height: 50px;
        margin: 0;
    }

    #subscribe-form-btn {
        display: flex;
        position: static;
        width: 100%;
    }

    #subscribe-form-btn-text {
        color: var(--Foundation-White-W50, #fefefe);
        text-align: center;

        /* material-theme/label/large */
        font-family: "Arima";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        letter-spacing: 0.1px;
    }
}
