.impact-chip-wrapper {
    width: fit-content;
    min-width: 257px;
    min-height: 95px;
    flex-shrink: 0;
    border-radius: 8px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1.5rem 1.5rem;
    overflow: hidden;
}

.impact-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    opacity: 0.4;
}

.impact-chip-text {
    font-family: "Arima";
    z-index: 1;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 128.571% */
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .impact-chip-wrapper {
        width: 100%;
        min-width: 100px;
        min-height: 50px;
        border-radius: 2px;
        padding: 1rem 1rem;
    }

    .impact-chip-text {
        font-size: 16px;
        line-height: 120%;
    }
}
