#impact-section {
    margin: 15vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#impact-content-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
}

.impact-col {
    display: flex;
    /* height: 50vh; */
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
}

#impact-cols-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    margin: 2rem 0;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #impact-section {
        margin: 0;
        width: 100%;
    }

    #impact-content-wrapper {
        display: flex;
        flex-direction: column;
        position: static;
        width: 100%;
    }

    #impact-cols-wrapper {
        width: 100%;
        padding: 0 20px;
        justify-content: space-between;
    }

    .impact-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: end;
        width: calc(50% - 4px);
    }
}
