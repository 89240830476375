#early-bird-banner {
    display: flex;
    width: 92%;
    padding: 36px;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
    gap: 4px;
    border-radius: 24px;
    background: linear-gradient(90deg, #464e57 0%, #141a20 100%);
    position: relative;
}

#early-bird-img-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
}
#early-bird-content-wrapper {
    width: 60%;
}

.early-bird-banner-title {
    color: var(--Old-Styles-White, #fff);

    /* material-theme/headline/large */
    font-family: "Arima";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 125% */
}
.early-bird-banner-para {
    color: var(--Old-Styles-Neutral-grey-80, #f7f7f7);

    /* material-theme/body/large */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #early-bird-banner {
        width: 100%;
    }

    #early-bird-content-wrapper {
        width: 100%;
        text-align: center;
        z-index: 1;
    }

    .early-bird-banner-title {
        font-size: 32px;
        line-height: 120%; /* 125% */
    }
    .early-bird-banner-para {
        font-size: 18px;
    }
}
