#feature-section {
    display: flex;
    justify-content: center;
    padding: 5rem 0;
}
#feature-section-wrapper {
    /* height: 913px; */
    width: 95vw;
    /* flex-shrink: 0; */
    border-radius: 56px;
    background: linear-gradient(115deg, #464e57 2.23%, #141a20 97.51%);
    padding: 4rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#feature-section-title-wrapper {
    width: 70%;
}

#features-container {
    width: 40vw;
}

#feature-section-title {
    align-self: stretch;
    color: var(--Foundation-White-W50, #fefefe);
    text-align: center;

    /* material-theme/display/medium */
    font-family: "Arima";
    font-size: 57px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px; /* 112.281% */
    letter-spacing: -0.25px;
}

#feature-section-subtitle {
    color: #f5f5f5;
    text-align: center;

    /* material-theme/headline/small */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 133.333% */
}

.feature-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 2rem 0;
    padding: 0 12px;
}

.feature-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 10px; Optional spacing */
    background-color: rgba(32, 42, 52, 0.4);
}

.feature-icon img {
    width: 50%;
    height: 50%;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #feature-section-wrapper {
        width: 100vw;
        border-radius: 24px;
    }

    #feature-section-title-wrapper {
        width: 85%;
    }

    #features-container {
        width: 100%;
    }

    #feature-section-title {
        font-size: 32px;
        line-height: 120%;
    }

    #feature-section-subtitle {
        font-size: 18px;
        line-height: normal;
    }
}
