.family-chip {
    display: flex;
    padding: 2px 16px;
    align-items: center;
    gap: 8px;
    width: fit-content;

    border-radius: 32px;
    background: #fff2ee;
}

#family-chip-text {
    color: var(--Foundation-Dark-B300, #202a34);
    text-align: center;

    /* material-theme/title/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #family-chip-text {
        font-size: 12px;
        line-height: 120%; /* 150% */
    }
    .family-chip {
        display: flex;
        padding: 4px 12px;
    }
}
