#family-component-wrapper {
    position: relative;
    height: 300vh;
    width: 100%;
    /* overflow: hidden; */
    /* background-color: yellow; */
}

.family-card {
    opacity: 0;
    position: absolute;
    transition: 0.5s ease;
}

#family-card-1 {
    top: 10vh;
    left: -50vw;
    z-index: 3;
}

#family-card-2 {
    top: 10vh;
    right: -50vw;
    z-index: 1;
}

#family-card-3 {
    bottom: 0vh;
    left: 30vw;
    z-index: 2;
}

.family-card.active {
    opacity: 1;
}

.family-card.stick {
    opacity: 1;
}

#family-card-1.active {
    position: fixed;
    top: 10vh;
    left: 5vw;
}

#family-card-2.active {
    position: fixed;
    top: 10vh;
    right: 4vw;
}

#family-card-3.active {
    position: fixed;
    bottom: 0;
    /* left: 30vw; */
}

#family-card-1.stick {
    position: absolute;
    top: auto;
    bottom: 40vh;
    left: 5vw;
}

#family-card-2.stick {
    position: absolute;
    bottom: 40vh;
    top: auto;
    right: 4vw;
}

#family-card-3.stick {
    position: absolute;
    bottom: -5vh;
    top: auto;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    #family-component-wrapper {
        position: inherit;
        display: flex;
        flex-direction: column;
        height: auto;
        margin: 5vh 0;
    }
    .family-card {
        opacity: 1;
        position: static;
        left: (50%);
        transform: translateX(0) translateY(0);
        margin: 5vh 0;
    }
    .family-card {
        scale: 0.8;
        margin: -10px 0;
    }
}
