.dialog-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40vw;
    min-height: 50vh;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 48px 0px rgba(32, 42, 52, 0.15);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3.5rem 40px;
    outline: none;
}

#cancel-circle-wrapper {
    position: relative;
    width: 100%;
}

#early-access-close-button {
    position: absolute;
    top: -28px;
    right: -12px;
    cursor: pointer;
}

#early-access-modal-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#modal-title {
    color: #020024;
    font-family: "Arima";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
}

#modal-content {
    align-self: stretch;
    color: #6c757d;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
}

#modal-form {
    width: 100%;
    outline: none;
}

#modal-form-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 300px; */
    flex-shrink: 0;
    border-radius: 12px;
}

#modal-form-email-input {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    outline: none;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 4px 8px 42px 0px rgba(149, 149, 149, 0.15);
}

#modal-form-name-input {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    outline: none;
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 4px 8px 42px 0px rgba(149, 149, 149, 0.15);
}

#modal-form-btn {
    display: flex;
    padding: 16px 24px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 2px solid var(--Foundation-Blue-B300, #3cbfb5);
    background: var(--Foundation-Blue-B300, #3cbfb5);
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    .dialog-wrapper {
        width: 90vw;
        min-height: 50vh;
        top: 50%;
        left: 50%;
        padding: 44px 24px 24px 24px;
    }
    #early-access-close-button {
        top: -28px;
        right: -8px;
    }
}
