.nav-wrapper {
    display: flex;
    width: 100%;
    padding: 12px 3.5rem;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    position: sticky;
    position: -webkit-sticky;
    top: 0; /* required */
    background: #fff;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.05);
}

.nav-element {
    color: var(--Foundation-Dark-B100, #7e8389);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 24px */
}

.logo-text {
    color: #000;
    text-align: center;
    font-family: "Arima";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 48px */
}

#logo-wrapper {
    height: 5vh;
    display: flex;
}

nav {
    /* width: 25%; */
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    position: sticky;
}

.nav-item {
    transition: all 0.3s ease;
    margin: 0 1.5rem;
}
.nav-item:first-child :hover {
    color: black;
    font-weight: 800;
}

.selected-nav-item {
    color: var(--Foundation-Dark-B300, #202a34);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
}

span {
    color: var(--Foundation-Dark-B300, #202a34);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 24px */
    transition: transform 0.1s linear;
}

/* For mobile view */

#mobile-nav {
    display: none;
}
#hamburger {
    display: none;
}
.bar {
    display: none;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
    nav {
        display: none;
    }
    #logo-wrapper {
        height: 30px;
        width: 62px;
    }
    .nav-wrapper {
        padding: 1rem 2rem;
    }

    /* For mobile view */

    #mobile-nav {
        position: absolute;
        display: block;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        z-index: 2;
        top: calc(6vh);
        left: -105vw;
        background-color: white;
        width: 100vw;
        height: 105vh;
    }
    #mobile-nav.active {
        left: 0;
    }
    #sidebar-wrapper {
        height: 100%;
        padding: 0 5vw;
        justify-content: space-between;
    }

    #hamburger {
        display: none;
        position: relative;
        height: 20px;
        width: 30px;
    }
    .nav-element {
        margin: 2vh 0;
    }
    .link-wrapper {
        height: 40%;
        align-items: flex-start;
        justify-content: space-evenly;
        padding: 0 5vw;
    }
    .sidebar-icon-row {
        /* background-color: yellow; */
    }
    .bar {
        display: block;
        position: absolute;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        background-color: #141a20;
        border: 2px solid #141a20;
        border-radius: 2px;
        width: 8px;
    }
    #bar-1 {
        width: 50%;
        top: 0;
        right: 0;
    }
    #bar-2 {
        width: 100%;
        top: 50%;
    }
    #bar-3 {
        width: 50%;
        top: 100%;
    }
    #hamburger.active .bar:nth-child(1) {
        transform: translateY(10px) translateX(-3px) rotate(45deg);
        width: 80%;
    }

    #hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }
    #hamburger.active .bar:nth-child(3) {
        transform: translateY(-10px) translateX(3px) rotate(-45deg);
        width: 80%;
    }
}
